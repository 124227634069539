import { App, Button, Form, GetProp, Input, Select, UploadProps } from "antd";
import { useState } from "react";
import { useAuth, useSupabase } from "../../AuthProvider";
import ProjectType from "../../types/ProjectType";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import { AnyObject } from "antd/es/_util/type";

function EditProject({
    projectModel,
    callback,
}: {
    projectModel?: ProjectType
    callback?: (project: AnyObject) => void
}) {
    const [loading, setLoading] = useState(false);
    const [project, setProject] = useState<ProjectType | undefined>(projectModel);
    const supabase = useSupabase();
    const navigate = useNavigate();
    const { profile } = useAuth();
    const { message } = App.useApp();

    type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

    const beforeUpload = (file: FileType) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        // return isJpgOrPng && isLt2M;
        return false;
    };

    async function updateProject(formData: AnyObject) {
        setLoading(true);

        // If a file has been uploaded.
        if (formData.thumbnail) {
            const file = formData.thumbnail.file;
            // TODO: Cancel existing files.
            if (project && project.thumbnail_url) {
                await supabase.storage.from('thumbnails')
                    .remove([project.thumbnail_url])
                    .catch((error) => {
                        message.error('File Error: '+error.message);
                    });
            }

            await supabase.storage
                .from('thumbnails')
                .upload(
                    profile.id+'/'+file.uid,
                    file,
                    {
                        cacheControl: '3600',
                        upsert: false
                    }
                ).then(({ data, error }) => {
                    if (error) {
                        message.error('Error uploading file: '+error.message);
                    } else if (data) {
                        formData.thumbnail_url = data.path;
                    }
                    setLoading(false);
                }).catch((error) => {
                    message.error(error.message);
                })
        }

        delete formData.thumbnail;

        if (project) {
            // Update existing project.
            await supabase
                .from('projects')
                .update({
                    ...formData,
                    profile_id: profile.profile_id,
                })
                .eq('id', project.id)
                .select('*')
                .single()
                .then(({ data, error }) => {
                    if (error) {
                        message.error('Error updating project: '+error.message);
                    } else if (data) {
                        message.success('Project Updated!');
                        setLoading(false)
                    }
                });
        } else {
            // Create new project.
            await supabase
                .from('projects')
                .insert({
                    ...formData,
                    profile_id: profile.profile_id,
                })
                .select()
                .single()
                .then(({ data, error }) => {
                    if (error) {
                        message.error(error.message);
                    }

                    if (data) {
                        message.success('Project Created!');
                        if (callback) {
                            callback(data)
                        } else {
                            navigate('../../project/' + data.id)
                        };
                        setLoading(false)
                    }
                });
        }
    }

    return (
        <Form
            name='update-project'
            onFinish={updateProject}
            layout='vertical'
            initialValues={{ ...project }}
        >
            <Form.Item name="thumbnail">
                <Dragger
                    name="thubmnail"
                    maxCount={1}
                    action=""
                    fileList={[]}
                    beforeUpload={beforeUpload}
                >
                    {project
                        ? <img
                            src={`https://hoxfetyabueltdiqrkck.supabase.co/storage/v1/object/public/thumbnails/${project?.thumbnail_url}`}
                            alt="avatar"
                            style={{ width: '100%', display: 'block' }}
                        />
                        : <button style={{ border: 0, background: 'none' }} type="button">
                            {loading ? <LoadingOutlined /> : <UploadOutlined />}
                            <div style={{ marginTop: 8 }}>Upload Thumbnail</div>
                        </button>
                    }
                </Dragger>
            </Form.Item>
            
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Every project needs a name!' }]}
                extra={'Even if you change it a million times, start with something.'}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Description"
                name="description"
            >
                <Input.TextArea />
            </Form.Item>

            <Form.Item
                label="Link"
                name="link"
                extra={'If your project is publicly available somewhere, include it here!'}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Project Status"
                name="status"
                rules={[{ required: true, message: "If you've made it this far, I think you're at least daydreaming." }]}
            >
                <Select
                    placeholder="Project Status"
                    options={[
                        { value: 'daydreaming', label: 'daydreaming' },
                        { value: 'in_progress', label: 'in_progress' },
                        { value: 'alpha_test', label: 'alpha_test' },
                        { value: 'beta_test', label: 'beta_test' },
                        { value: 'test_flight', label: 'test_flight' },
                        { value: 'just_launched', label: 'just_launched' },
                    ]}
                />
            </Form.Item>

            <Form.Item>
                <Button
                    type='primary'
                    htmlType="submit"
                    loading={loading}
                >
                    Save
                </Button>
            </Form.Item>
        </Form>
    )
}

export default EditProject;
