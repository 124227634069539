import { useNavigate, useParams } from "react-router-dom";
import { Composer, Post, Post as PostUI } from "../components/Feed";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { useAuth, useSupabase } from "../AuthProvider";
import { AnyObject } from "antd/es/_util/type";
import { Button, Divider, Empty, List, Skeleton } from "antd";

function SinglePost() {
    let params = useParams();
    const supabase = useSupabase();
    const [post, setPost] = useState<AnyObject>();
    const [thread, setThread] = useState<AnyObject[]>();
    const [loadingThread, setLoadingThread] = useState(false);
    let { profile } = useAuth();
    let navigate = useNavigate();

    async function fetchThread(postId: string) {
        setLoadingThread(true)

        await supabase
            .from('posts')
            .select(`
                *,
                profiles(profile_id, profile_image, name, expertise),
                projects(id, name),
                likes:liked_posts(profile_id)
            `)
            .eq('parent_post_id', postId)
            .order('created_at', { ascending: false })
            .then((response) => {
                if (response.data) setThread(response.data);
                setLoadingThread(false)
            })
    }

    async function fetchPost(postId: string) {
        await supabase
            .from('posts')
            .select(`
                *,
                profiles(profile_id, profile_image, name, expertise),
                projects(id, name),
                likes:liked_posts(profile_id),
                comments:posts(count)
            `)
            .eq('id', postId)
            .order('created_at', { ascending: false })
            .single()
            .then((response) => {
                if (response.data) setPost(response.data);
            })
    }

    useEffect(() => {
        if (params?.postId) {
            fetchPost(params?.postId)
            fetchThread(params?.postId)
        }
    }, [params]);

    if (!post) return (
        <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
                <span>
                    Welp, this is awkward, there's nothing here.
                </span>
            }
        />
    )

    return (
        <>
            <Button
                type="text"
                icon={<ArrowLeftOutlined />}
                onClick={() => navigate(-1)}
            >
                Back
            </Button>
            <Divider />
            <PostUI
                enableLinks={true}
                post={{
                    ...post,
                    author_name: post.profiles.name,
                    author_image: post.profiles.profile_image,
                    author_expertise: post.profiles.expertise,
                    likes: post.likes?.length,
                    comments: post.comments ? post.comments[0].count : 0,
                    liked: post.likes?.some((e: { profile_id: any; }) => e.profile_id === profile?.profile_id),
                }}
            />
            <Divider />
            {loadingThread
                ? <Skeleton avatar paragraph={{ rows: 2 }} />
                : (thread && thread.length > 0)
                    ? <List
                        itemLayout="vertical"
                        dataSource={thread}
                        renderItem={(post) => (
                            <List.Item>
                                <Post post={{
                                    ...post,
                                    author_name: post.profiles.name,
                                    author_image: post.profiles.profile_image,
                                    author_expertise: post.profiles.expertise,
                                    likes: post.likes?.length,
                                    liked: post.likes?.some((e: { profile_id: any; }) => e.profile_id === profile?.profile_id),
                                }} />
                            </List.Item>
                        )}
                    />
                    : <>Be the first to leave a comment</>
            }
            <Divider />
            <Composer parentId={Number(params.postId)} customPrompt={'Add to the conversation...'}/>
        </>
    )
}

export default SinglePost;
