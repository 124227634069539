import {
    GithubOutlined,
    MediumOutlined,
    BehanceOutlined,
    DribbbleOutlined,
    XOutlined,
    SpotifyOutlined,
    TwitchOutlined
} from '@ant-design/icons';

const SocialIcon = ({ type }: { type: string }) => {
    switch (type) {
        case 'behance':
            return <BehanceOutlined />
        case 'dribble':
            return <DribbbleOutlined />
        case 'github':
            return <GithubOutlined />
        case 'medium':
            return <MediumOutlined />
        case 'spotify':
            return <SpotifyOutlined />
        case 'twitch':
            return <TwitchOutlined />
        case 'x':
            return <XOutlined />
        case 'posts':
            return <svg width="1em" height="1em" viewBox="20 20 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" style={{ fill: 'currentColor', fillOpacity: 1 }} d="M75.0856 145.903L43.9713 137.565C39.4431 136.351 35.5827 133.387 33.2391 129.327C30.8955 125.267 30.2606 120.442 31.4742 115.913L52.277 38.2949C53.4913 33.7667 56.4545 29.9063 60.5148 27.5627C64.5751 25.2191 69.4001 24.5842 73.9285 25.7978L136.028 42.4285C140.557 43.6428 144.417 46.6059 146.761 50.6663C149.104 54.7266 149.739 59.5516 148.526 64.0799L136.028 110.706C134.882 114.988 132.903 119.001 130.205 122.517C127.507 126.034 124.142 128.984 120.304 131.201L100.697 142.521C96.8588 144.738 92.6214 146.177 88.227 146.756C83.8326 147.335 79.3671 147.05 75.0856 145.903ZM40.7892 118.408C40.516 119.427 40.4463 120.49 40.584 121.537C40.7218 122.583 41.0643 123.592 41.592 124.506C42.1197 125.42 42.8223 126.221 43.6596 126.863C44.4969 127.506 45.4526 127.977 46.472 128.25L75.9663 136.157L82.2085 112.873C82.809 110.63 83.8454 108.528 85.2586 106.685C86.6717 104.843 88.4339 103.298 90.4444 102.137C92.455 100.975 94.6745 100.222 96.9764 99.9185C99.2782 99.6153 101.617 99.7684 103.86 100.369L127.144 106.605L139.211 61.5921C139.484 60.5727 139.553 59.5095 139.416 58.4631C139.278 57.4168 138.935 56.4079 138.408 55.4939C137.88 54.58 137.177 53.7789 136.34 53.1365C135.503 52.4941 134.547 52.023 133.528 51.7499L71.4213 35.0999C69.3634 34.5502 67.1715 34.8399 65.3272 35.9055C63.4829 36.9711 62.1371 38.7254 61.5856 40.7828L40.7892 118.408ZM123.338 115.573L101.366 109.684C100.346 109.411 99.283 109.341 98.2367 109.479C97.1903 109.617 96.1814 109.959 95.2675 110.487C94.3535 111.015 93.5525 111.717 92.9101 112.555C92.2677 113.392 91.7965 114.348 91.5234 115.367L85.6349 137.34C89.2403 137.066 92.7381 135.986 95.8692 134.177L115.483 122.856C118.614 121.048 121.299 118.559 123.338 115.573Z" />
            </svg>
        case 'retro':
            return <svg width="1em" height="1em" viewBox="0 0 684 684" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" style={{ fill: 'currentColor', fillOpacity: 1 }} d="M597.598 683.995L598.495 684C461.331 684 376.2 598.867 376.2 461.699H684V683.995H597.598ZM487.348 393.302V393.3H376.2V0.00701352H487.348V0C608.686 0 683.994 75.3095 683.994 196.651C683.994 317.992 608.686 393.302 487.348 393.302ZM0 584.242V99.7598C0 38.2088 38.2035 0.00701352 99.7596 0.00701352H307.8V683.995H99.7596C38.2035 683.995 0 645.794 0 584.242Z" />
            </svg>
        default:
            <></>
    }
    return <></>
}

export default SocialIcon;