import { Button, Empty, List } from "antd";
import { useEffect, useState } from "react";
import { useSupabase } from "../../AuthProvider";
import { useNavigate } from "react-router-dom";
import Post from "./Post";
import { AnyObject } from "antd/es/_util/type";

function Timeline({
    src,
    data,
} : {
    src?: string
    data?: AnyObject[]
}) {
    const [feedData, setFeedData] = useState<AnyObject[]>();
    const supabase = useSupabase();
    let navigate = useNavigate();

    async function fetchPosts() {
        await supabase
            .from(src ? src : '' )
            .select('*')
            .then((response) => {
                if (response.data) setFeedData(response.data);
            })
    }

    useEffect(() => {
        if (src) {
            fetchPosts();
        }

        if (data) {
            setFeedData(data);
        }
    }, [])

    if (!feedData || feedData.length === 0) return (
        <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
                <span>
                    It's a bit quiet over here
                </span>
            }
        >
            <Button onClick={() => navigate('/explore')}>Find Someone to Follow</Button>
        </Empty>
    )

    return (
        <List
            itemLayout="vertical"
            dataSource={feedData}
            renderItem={(post) => (
                <List.Item>
                    <Post post={post} />
                </List.Item>
            )}
        />
    )

}

export default Timeline;
