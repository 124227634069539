import React, { useEffect } from "react";
import { AnyObject } from "antd/es/_util/type";
import { useState } from 'react'
import { ConfigProvider } from "antd";

interface AuthContextType {
  theme: AnyObject;
  isLight: boolean;
  toggleTheme: () => void;
}

let ThemeContext = React.createContext<AuthContextType>(null!);

const lightTheme = {
  bg: '#FFFCF0',
  bg_2: '#F2F0E5',
  ui: '#E6E4D9',
  ui_2: '#DAD8CE',
  ui_3: '#CECDC3',
  tx_3: '#B7B5AC',
  tx_2: '#6F6E69',
  tx: '#100F0F',
  re: '#AF3029',
  or: '#BC5215',
  ye: '#AD8301',
  gr: '#66800B',
  cy: '#24837B',
  bl: '#205EA6',
  pu: '#5E409D',
  ma: '#A02F6F',
}

const darkTheme = {
  bg: '#100F0F',
  bg_2: '#1C1B1A',
  ui: '#282726',
  ui_2: '#343331',
  ui_3: '#403E3C',
  tx_3: '#575653',
  tx_2: '#878580',
  tx: '#CECDC3',
  re: '#D14D41',
  or: '#DA702C',
  ye: '#D0A215',
  gr: '#879A39',
  cy: '#3AA99F',
  bl: '#4385BE',
  pu: '#8B7EC8',
  ma: '#CE5D97',
}

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [theme, setTheme] = useState(localStorage.getItem('theme') === 'dark' ? darkTheme : lightTheme);
  const [isLight, setIsLight] = useState(true);

  function toggleTheme() {
    setTheme(theme === lightTheme ? darkTheme : lightTheme);
    setIsLight(theme !== lightTheme);
  }

  useEffect(() => {
    localStorage.setItem('theme', theme === lightTheme ? 'light' : 'dark');
  }, [theme]);
  
  let value = { theme, isLight, toggleTheme, };

  return (
    <ThemeContext.Provider value={value}>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Inconsolata',
            colorPrimary: theme.cy,
            colorTextBase: theme.tx,
            colorBgBase: theme.bg,
            colorBgContainer: theme.ui,
            colorBorder: theme.ui,
            colorBorderSecondary: theme.ui_2,
            colorLink: theme.bl,
          },
          components: {
            Input: {
              colorBgContainer: theme.bg,
              colorTextDescription: theme.tx_2,
              colorTextPlaceholder: theme.tx_2,
            },
            Tabs: {
              itemColor: theme.tx_2,
              itemSelectedColor: theme.tx,
              itemActiveColor: theme.tx,
              itemHoverColor: theme.tx_3,
            },
          },
        }}
      >
        {children}
      </ConfigProvider>
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  return React.useContext(ThemeContext);
}