import { Avatar as AntDAvatar, Badge } from "antd";
import { useTheme } from "../utilities/ThemeProvider";
import { useEffect, useState } from "react";

const ExpertiseEmoji = [
    '💻',
    '🖌️',
    '🖋️',
    '📐',
    '🖥️',
    '🛠️',
    '🎶',
    '📸',
    '🎥'
]

const Avatar = ({
    name,
    image,
    expertise,
    type = 'profile',
    size = 32,
}: {
    name: string
    image?: string
    expertise?: number
    type?: 'profile' | 'project',
    size?: number,
}) => {
    const { theme } = useTheme();
    const [icon, setIcon] = useState<string | null>(null);

    useEffect(() => {
        if (type === 'profile' && expertise !== undefined) {
            setIcon(ExpertiseEmoji[expertise]);
        }
    }, [])

    const BuildAvatar = () => (
        !image
            ? <AntDAvatar
                shape={type === 'profile' ? 'circle' : 'square'}
                size={size}
            >
                {name.charAt(0)}
            </AntDAvatar>
            : <AntDAvatar
                size={size}
                shape={type === 'profile' ? 'circle' : 'square'}
                style={{ border: `2px solid ${theme.ui}` }}
                src={`https://hoxfetyabueltdiqrkck.supabase.co/storage/v1/object/public/${type === 'profile' ? 'profile_images' : 'thumbnails'}/${image}`}
            />
    )

    return (
        <Badge
            count={icon}
            style={{ padding: 0, paddingBlockStart: 1, paddingInlineStart: size === 32 ? 2 : 0, fontSize: size === 64 ? '0.8rem' : '0.6rem' }}
            color={theme.ui}
            size={size === 64 ? 'default' : 'small'}
            offset={size === 32 ? [-4, 28] : [-8, 56]}
        >
            <BuildAvatar />
        </Badge>
    )
}

export default Avatar;