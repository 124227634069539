import {
    App,
    Avatar,
    Button,
    Flex,
    Form,
    GetProp,
    Input,
    Select,
    Space,
    Tabs, TabsProps, Tag, Upload, UploadProps
} from "antd";
import { useAuth, useSupabase } from "../../AuthProvider";
import { useState } from "react";
import {
    PlusOutlined,
    LoadingOutlined,
    GithubOutlined,
    MediumOutlined,
    BehanceOutlined,
    DribbbleOutlined,
    XOutlined,
    SpotifyOutlined,
    TwitchOutlined
} from "@ant-design/icons";
import { useTheme } from "../../utilities/ThemeProvider";
import SocialIcon from "../../components/SocialIcon";

function EditModal() {
    const { user, profile, updateProfile, updateEmail } = useAuth();
    const [loading, setLoading] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const { message } = App.useApp();
    const supabase = useSupabase();
    const [profileForm] = Form.useForm();
    const { theme } = useTheme();

    type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

    async function uploadImage(file: FileType) {
        // If a file has been uploaded.
        if (profile.profile_image && profile.profile_image.length > 0) {
            await supabase.storage.from('profile_images')
                .remove([profile.profile_image])
                .catch((error) => {
                    message.error('Error: ' + error.message);
                });
        }

        // Upload image
        await supabase.storage
            .from('profile_images')
            .upload(
                profile.id + '/' + file.uid,
                file,
                {
                    cacheControl: '3600',
                    upsert: false
                }
            ).then(({ data, error }) => {
                if (error) {
                    message.error('Error: ' + error.message);
                } else if (data) {
                    updateProfile({ profile_image: data.path }).then(res => {
                        setImageLoading(false);
                    });
                }
            }).catch((error) => {
                message.error(error.message);
            })

        setImageLoading(false);

    }

    const beforeUpload = (file: FileType) => {
        // setImageLoading(true);
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        // return isJpgOrPng && isLt2M;
        uploadImage(file);
        return false;
    };

    async function saveProfile(formData: any) {
        setLoading(true);
        updateProfile(formData).then(res => {
            setLoading(false);
        });
    }

    async function saveAccount(formData: any) {
        setLoading(true);
        updateEmail(formData.email).then(res => {
            setLoading(false);
        });
    }

    const saveLinks = (values: any) => {
        setLoading(true);
        updateProfile({ social_links: values }).then(res => {
            setLoading(false);
        });
    };

    const tabs: TabsProps['items'] = [
        {
            key: '1',
            label: 'Profile',
            children: <Form
                name='update'
                onFinish={saveProfile}
                layout='vertical'
                // size="small"
                initialValues={profile}
                form={profileForm}
            >
                <Flex align="start" justify="space-between">
                    <Space direction="vertical">
                        <label>Profile Image</label>
                        <Upload
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                        >
                            {profile.profile_image
                                ? <Avatar
                                    size={84}
                                    shape="square"
                                    src={`https://hoxfetyabueltdiqrkck.supabase.co/storage/v1/object/public/profile_images/${profile.profile_image}`}
                                />
                                : imageLoading
                                    ? <LoadingOutlined />
                                    : <PlusOutlined />
                            }
                        </Upload>
                    </Space>
                    <Space direction="vertical">
                        <Form.Item
                            label={<><Tag color={theme.pu}>New</Tag> Expertise</>}
                            name="expertise"
                        >
                            <Select
                                style={{ minWidth: 200 }}
                                options={[
                                    { value: 0, label: '💻 developer' },
                                    { value: 1, label: '🖌️ artist' },
                                    { value: 2, label: '🖋️ writer' },
                                    { value: 3, label: '📐 UI designer' },
                                    { value: 4, label: '🖥️ graphic designer' },
                                    { value: 5, label: '🛠️ product designer' },
                                    { value: 6, label: '🎶 musician' },
                                    { value: 7, label: '📸 photographer' },
                                    { value: 8, label: '🎥 cinematographer' },
                                ]}
                            />
                        </Form.Item>
                    </Space>
                </Flex>
                <br />
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input your name!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Location"
                    name="location"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Bio"
                    name="bio"
                    hasFeedback
                    validateFirst
                    rules={[{ max: 100, message: "Less is more. This isn't a dating app" }]}
                >
                    <Input.TextArea />
                </Form.Item>

                <Form.Item>
                    <Button
                        type='primary'
                        size="small"
                        htmlType="submit"
                        loading={loading}
                        disabled={imageLoading}
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>,
        },
        {
            key: '2',
            label: <><Tag color={theme.pu}>New</Tag> Links</>,
            children: <Form onFinish={saveLinks} size="small" initialValues={profile.social_links}>
                <Form.Item name={'posts'}>
                    <Input
                        prefix={<SocialIcon type="posts" />}
                        placeholder="Posts.cv"
                    />
                </Form.Item>
                <Form.Item name={'retro'}>
                    <Input
                        prefix={<SocialIcon type="retro" />}
                        placeholder="Retro.app"
                    />
                </Form.Item>
                <Form.Item name={'behance'}>
                    <Input
                        prefix={<SocialIcon type="behance" />}
                        placeholder="Behance"
                    />
                </Form.Item>
                <Form.Item name={'dribble'}>
                    <Input
                        prefix={<SocialIcon type="dribble" />}
                        placeholder="Dribble"
                    />
                </Form.Item>
                <Form.Item name={'github'}>
                    <Input
                        prefix={<SocialIcon type="github" />}
                        placeholder="Github"
                    />
                </Form.Item>
                <Form.Item name={'medium'}>
                    <Input
                        prefix={<SocialIcon type="medium" />}
                        placeholder="Medium"
                    />
                </Form.Item>
                <Form.Item name={'spotify'}>
                    <Input
                        prefix={<SocialIcon type="spotify" />}
                        placeholder="Spotify"
                    />
                </Form.Item>
                <Form.Item name={'x'}>
                    <Input
                        prefix={<SocialIcon type="x" />}
                        placeholder="X"
                    />
                </Form.Item>
                <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>,
        },
        {
            key: '3',
            label: 'Account',
            children: <Form
                name='account'
                onFinish={saveAccount}
                onChange={() => setEnableSubmit(true)}
                layout='vertical'
                initialValues={user}
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Email is required' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item>
                    <Button
                        type='primary'
                        htmlType="submit"
                        loading={loading}
                        disabled={!enableSubmit}
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>,
        },
    ];

    return (
        <>
            <Tabs
                tabPosition={'left'}
                items={tabs}
            />
        </>
    )
}

export default EditModal;
