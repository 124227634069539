import {
    App,
    Badge,
    Button,
    Card,
    Col,
    Divider,
    Empty,
    Flex,
    Grid,
    List,
    Modal,
    Row,
    Space,
    Tabs,
    TabsProps,
    Typography
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    EditOutlined,
    FolderAddOutlined,
    EnvironmentOutlined,
    LogoutOutlined,
    HeartOutlined,
    HeartTwoTone,
    ExportOutlined,
    CloseOutlined
} from '@ant-design/icons';
import { useTheme } from '../../utilities/ThemeProvider';
import { useAuth, useSupabase } from '../../AuthProvider';
import EditModal from './editModal';
import PostType from '../../types/PostType';
import ProjectType from '../../types/ProjectType';
import { Post, Composer } from '../../components/Feed';
import EditProject from '../Project/editProject';
import ProfileType from '../../types/ProfileType';
import SocialIcon from '../../components/SocialIcon';
import FollowButton from '../../components/Follow';
import Avatar from '../../components/Avatar';

function User() {
    const [myAccount, setMyAccount] = useState(false);
    const [editModalOpen, setIsEditModalOpen] = useState(false);
    const [projectModalOpen, setProjectModalOpen] = useState(false);
    const [posts, setPosts] = useState<PostType[]>([]);
    const [projects, setProjects] = useState<ProjectType[]>([]);
    const [profile, setProfile] = useState<ProfileType>();
    const [liked, setLiked] = useState(false)
    const [likeCount, setLikeCount] = useState(0)
    const [followingTab, setFollowingTab] = useState('people')
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const { profile: userProfile, signOut } = useAuth();
    const supabase = useSupabase();
    let navigate = useNavigate();
    let { theme } = useTheme();
    let params = useParams();
    const { notification } = App.useApp();

    async function getProfile() {
        const { data, error } = await supabase
            .from('profiles')
            .select(`
                *,
                liked_projects(
                    projects(id, name, description, thumbnail_url)
                )
            `)
            .eq('profile_id', params.userId)
            .single()

        if (error) notification.error({
            message: 'Profile not Found',
        });

        if (data) {
            setProfile(data)

            await supabase
                .from('liked_profiles')
                .select()
                .eq('profile_id', userProfile.profile_id)
                .eq('liked_id', data.profile_id)
                .then(({ data }) => {
                    if (data && data.length > 0) setLiked(true)
                })
        };
    }

    async function getPosts() {
        await supabase
            .from('posts')
            .select(`
                *,
                profiles(profile_id, profile_image, name, expertise),
                projects(id, name),
                likes:liked_posts(profile_id),
                comments:posts(count)
            `)
            .eq('profile_id', params.userId)
            .order('created_at', { ascending: false })
            .then((response) => {
                if (response.data) setPosts(response.data);
            })
    }

    async function getProjects() {
        await supabase
            .from('projects')
            .select(`
                *,
                profiles(name),
                posts(*)
            `)
            .eq('profile_id', params.userId)
            .order('created_at', { ascending: false })
            .then((response) => {
                if (response.data) setProjects(response.data)
            })
    }

    useEffect(() => {
        getProfile();
    }, [params]);

    useEffect(() => {
        setMyAccount(profile?.profile_id === userProfile?.profile_id);
        getPosts();
        getProjects();
    }, [profile]);

    async function follow() {
        if (!liked && profile) {
            await supabase
                .from('liked_profiles')
                .insert({
                    profile_id: userProfile.profile_id,
                    liked_id: profile.profile_id,
                })
                .then(() => {
                    setLiked(true);
                    setLikeCount(likeCount + 1)
                })
        } else {
            // unlike
            await supabase
                .from('liked_profiles')
                .delete()
                .eq('profile_id', userProfile.profile_id)
                .eq('liked_id', profile?.profile_id)
                .then(() => {
                    setLiked(false)
                })
        }
    }

    const toggleEditModal = () => {
        setIsEditModalOpen(!editModalOpen);
    };

    const toggleProjectModal = () => {
        setProjectModalOpen(!projectModalOpen);
    };

    const followingTabs: TabsProps['items'] = [
        {
            key: 'projects',
            label: 'Projects',
            children: <>
                <List
                    itemLayout="horizontal"
                    dataSource={profile?.liked_projects}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar name={item.projects.name} image={item.projects.thumbnail_url} type='project' />}
                                title={<a href={"/project/" + item.projects.id}>{item.projects.name} <ExportOutlined /></a>}
                                description={item.projects.description}
                            />
                        </List.Item>
                    )}
                />
            </>,
        },
    ];

    const tabs: TabsProps['items'] = [
        {
            key: '1',
            label: 'Posts',
            children: <>
                {myAccount && (<Composer callback={getPosts} />)}
                {posts.length > 0
                    ? <List
                        itemLayout="vertical"
                        dataSource={posts}
                        renderItem={(post) => (
                            <List.Item>
                                <Post post={{
                                    ...post,
                                    author_name: post.profiles.name,
                                    author_image: post.profiles.profile_image,
                                    author_expertise: post.profiles.expertise,
                                    likes: post.likes?.length,
                                    comments: post.comments ? post.comments[0].count : 0,
                                    liked: post.likes?.some(e => e.profile_id === profile?.profile_id),
                                }} />
                            </List.Item>
                        )}
                    />
                    : <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            <span>
                                {profile?.name} hasn't posted anything yet.
                            </span>
                        }
                    />
                }
            </>,
        },
        {
            key: '2',
            label: 'Projects',
            children: <List
                itemLayout="vertical"
                dataSource={projects}
                renderItem={(project) => (
                    <List.Item>
                        <Flex justify="space-between">
                            <Space align="center" onClick={() => navigate("/project/" + project.id)}>
                                <Avatar name={project.name} image={project.thumbnail_url} type='project' />
                                <div>
                                    <Typography.Title level={4} style={{ margin: 0 }}>{project.name}</Typography.Title>
                                    <Typography.Text style={{ margin: 0 }}>{project.description}</Typography.Text>
                                </div>
                            </Space>
                            <FollowButton type="project" id={project.id} />
                        </Flex>
                    </List.Item>
                )}
            />,
        },
    ];

    if (myAccount) tabs[2] = {
        key: '3',
        label: 'Following',
        children: <>
            <Tabs
                defaultActiveKey="1"
                items={followingTabs}
            />
        </>,
    };

    if (!profile) return <></>

    return (
        <>
            <Row justify="center" align="top" gutter={16}>
                {/* SIDEBAR */}
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Flex vertical align={!screens.md ? 'flex-start' : 'flex-end'}>
                        <Flex vertical={screens.md} gap={8} align={!screens.md ? 'flex-start' : 'flex-end'}>
                            <Avatar
                                name={profile.name}
                                image={profile.profile_image}
                                expertise={profile.expertise}
                                size={64}
                            />
                            <Space direction='vertical' align={!screens.md ? 'start' : 'end'} size={0}>
                                <Typography.Title
                                    level={1}
                                    style={{
                                        color: theme.tx,
                                        marginBlock: 0,
                                    }}
                                >{profile.name}</Typography.Title>

                                {profile.location &&
                                    <Typography.Title
                                        level={5}
                                        style={{
                                            color: theme.tx_2,
                                            margin: 0,
                                            marginBlockEnd: 4,
                                        }}>
                                        <EnvironmentOutlined color={theme.tx_2} /> {profile.location}
                                    </Typography.Title>
                                }
                            </Space>
                        </Flex>
                        <Flex gap={8} align="center">
                            {!myAccount ? (
                                <Button
                                    style={{
                                        color: theme.tx,
                                    }}
                                    icon={liked ? <HeartTwoTone twoToneColor={theme.re} /> : <HeartOutlined />}
                                    onClick={follow}
                                    size={liked ? 'small' : 'middle'}
                                    shape='round'
                                    type={liked ? "text" : "default"}
                                >
                                    {liked ? 'Following' : `Follow ${profile.name}`}
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        style={{
                                            color: theme.tx,
                                            backgroundColor: theme.bg,
                                            borderColor: theme.ui,
                                        }}
                                        onClick={() => { signOut(() => navigate('/')) }}
                                        size='small'
                                    >
                                        <LogoutOutlined /> log out
                                    </Button>
                                    <Badge color={theme.pu} size='small' count={'new'}>
                                        <Button
                                            style={{
                                                color: theme.tx,
                                                backgroundColor: theme.bg,
                                                borderColor: theme.ui,
                                            }}
                                            onClick={toggleEditModal}
                                            size='small'
                                        >
                                            <EditOutlined /> edit account
                                        </Button>
                                    </Badge>
                                </>
                            )}
                        </Flex>
                        <Divider style={{ marginBlock: 8, borderColor: theme.ui }} />
                        <Typography.Text
                            style={{
                                fontFamily: 'Inconsolata',
                                color: theme.tx,
                                textAlign: 'right',
                            }}
                        >
                            {profile.bio}
                        </Typography.Text>
                        <Flex gap={8} >
                            {profile.social_links && Object.keys(profile.social_links).map((link: string, index: number) => {
                                if (profile.social_links && profile.social_links[link].length > 0) return (
                                    <Button
                                        size='small'
                                        href={profile.social_links[link]}
                                        target='_blank'
                                        icon={<SocialIcon type={link} />}
                                    />
                                )
                            })}
                        </Flex>
                        <br />
                    </Flex>
                    {!localStorage.getItem('hide_welcome_message') &&
                        <Card
                            size="small"
                            title="Just saying Hi 👋🏼"
                            extra={<Button onClick={() => localStorage.setItem('hide_welcome_message', 'true')} icon={<CloseOutlined />} />}
                            actions={[
                                <a href="/about">Read More</a>
                            ]}
                        >
                            <>
                                Hi, I'm <a href='1'>Adam</a>.
                                <br />
                                My goal for <strong>logo_coming_soon</strong> is to build a platform
                                where creatives like us feel comfortable sharing a raw and unfiltered look at our work. I <em>don't</em> want
                                the content posted here to be perfect and flawless.
                                <br />
                                <br />
                                I want logo_coming_soon to be a home where creatives can "<em><a href="/about">work with the garage door open</a></em>". Where anyone is welcome
                                to stop by at any moment, ask questions, share ideas, or just hang out and sip coffee.
                                <br />
                                <br />
                                I appreciate you jumping on board early, let's build something powerful together.
                                <br />
                                <br />
                                <em>-Adam Bailey</em>
                            </>
                        </Card>
                    }
                    <br />
                </Col>
                {/* FEED */}
                <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <Tabs
                        defaultActiveKey="1"
                        items={tabs}
                        type='card'
                        tabBarExtraContent={<>
                            {myAccount && <Button size='small' type='text' onClick={toggleProjectModal}><FolderAddOutlined /> New Project</Button>}
                        </>}
                    />
                </Col>
            </Row>
            <Modal
                title="Edit Account"
                open={editModalOpen} onCancel={toggleEditModal}
                footer={[]}
            >
                <EditModal />
            </Modal>
            <Modal
                title="Create Project"
                open={projectModalOpen} onCancel={toggleProjectModal}
                footer={[]}
            >
                <EditProject />
            </Modal>
        </>
    )
}

export default User;
