import React from 'react';
import {
    HomeOutlined,
    MoonOutlined,
    SunOutlined,
    UserOutlined,
    LoginOutlined,
    BellOutlined,
    UsergroupAddOutlined,
} from '@ant-design/icons';
import {
    Button,
    Col,
    Divider,
    Flex,
    Row,
} from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '../utilities/ThemeProvider';
import { useAuth } from '../AuthProvider';

const BaseLayout: React.FC = () => {
    let navigate = useNavigate();
    let { theme, isLight, toggleTheme } = useTheme();
    const { session, profile } = useAuth();
    const { pathname } = useLocation();
    let path = pathname.split('/')[1];

    return (
        <div style={{ padding: 24, backgroundColor: theme.bg, minHeight: '100vh' }}>
            <Row justify="center" align="top">
                <Col xs={24} sm={20} md={24} lg={18} xl={18}>
                    <Flex justify='space-between' style={{ marginBlockEnd: 16 }} align='center'>
                        <div>
                            {session
                                ? <>
                                    <Button
                                        type={'text'}
                                        size='large'
                                        onClick={() => navigate('/feed')}
                                        style={{ color: (path === 'feed') ? theme.cy : theme.tx }}
                                        icon={<HomeOutlined />}
                                    >
                                        {(path === 'feed') ? 'Home' : '' }
                                    </Button>
                                    <Button
                                        type={'text'}
                                        size='large'
                                        onClick={() => navigate('/explore')}
                                        style={{ color: (path === 'explore') ? theme.cy : theme.tx }}
                                        icon={<UsergroupAddOutlined />}
                                    >
                                        {(path === 'explore') ? 'Explore' : '' }
                                    </Button>
                                    <Divider type="vertical" />
                                    <Button
                                        type={'text'}
                                        size='large'
                                        onClick={() => navigate('/notifications')}
                                        style={{ color: (path === 'notifications') ? theme.cy : theme.tx }}
                                        icon={<BellOutlined />}
                                    >
                                        {(path === 'notifications') ? 'Notifications' : '' }
                                    </Button>
                                    <Button
                                        type={'text'}
                                        size='large'
                                        onClick={() => navigate('/profile/' + profile.profile_id)}
                                        style={{ color: (path === 'profile') ? theme.cy : theme.tx }}
                                        icon={<UserOutlined />}
                                    >
                                        {(path === 'profile') ? 'Profile' : '' }
                                    </Button>
                                </>
                                : <Button
                                    type='text'
                                    size='large'
                                    onClick={() => navigate('/')}
                                    icon={<HomeOutlined style={{ color: theme.tx }} />}
                                />
                            }
                        </div>
                        <div>
                            <Button
                                type='text'
                                size='large'
                                onClick={toggleTheme}
                                icon={isLight ? <SunOutlined style={{ color: theme.tx }} /> : <MoonOutlined style={{ color: theme.tx }} />}
                            />
                            {!session &&
                                <Button
                                    size='large'
                                    onClick={() => { navigate('/login') }}
                                    icon={<LoginOutlined style={{ color: theme.tx }} />}
                                >
                                    Login
                                </Button>
                            }
                        </div>
                    </Flex>
                </Col>
            </Row>
            <Row justify="center" align="top">
                <Col xs={24} sm={20} md={24} lg={18} xl={18}>
                    <Outlet />
                </Col>
            </Row>
            <Row justify="center" align="top">
                <Col xs={24} sm={20} md={24} lg={18} xl={18}>
                    <footer style={{ paddingTop: 24 }}>
                        <p style={{
                            textAlign: 'center',
                            fontFamily: 'Inconsolata',
                            color: theme.tx_2,
                        }}>creating with the garage door open since {new Date().getFullYear()}</p>
                    </footer>
                </Col>
            </Row>
        </div>
    );
};

export default BaseLayout;