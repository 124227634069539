import React, { useEffect, useState } from 'react';
import { Card, Flex, Button, Form, Input, Row, Col, Typography, Segmented } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import { AnyObject } from 'antd/es/_util/type';
import EditProject from './Project/editProject';
import { Composer } from '../components/Feed';
import { useTheme } from '../utilities/ThemeProvider';
import { RocketOutlined } from '@ant-design/icons';

type FieldType = {
    email?: string;
    password?: string;
    name?: string;
};

const tabs = [
    {
        key: 'login',
        label: 'login',
    },
    {
        key: 'signup',
        label: 'join',
    },
];

const onboardingText = [
    {
        title: 'Join the Creatives',
        content: "Whether you're building software, designing a physical product, writing poetry, creating music, or anything in between; celebrate your creativity with like-minded individuals.",
    },
    {
        title: 'Share your first project?',
        content: "If you're ready, we'd love to see what you're building! This isn't a cover letter, or a portfolio; instead, think of this as the temporary construction walls in the airport. They're not pretty, but they show that *something* is happening, this is your something, and we'll be here to cheer you along until it's complete!",
    },
    {
        title: 'Write your First Post',
        content: "What was the last thing you built on your project? What's been the most challenging aspect? What's the most creative thing you did this week? What's blocking your creativity?",
    }
]

const Login: React.FC = () => {
    let [params] = useSearchParams();
    const [activeTab, setActiveTab] = useState<string>(`${params.get('type') === 'signup' ? 'signup' : 'login'}`);
    const [onboarding, setOnboarding] = useState<number>(0);
    const [hasProject, setHasProject] = useState<string>('')
    let navigate = useNavigate();
    let auth = useAuth();
    const { theme } = useTheme()

    const changeTab = (key: string) => {
        setActiveTab(key);
    };

    useEffect(() => {
        if (auth.user && auth.profile && auth.session) {
            // Redirect already logged in.
            navigate('/feed', { replace: true })
        }
    }, [])

    const content: Record<string, React.ReactNode> = {
        login: <Form
            name='login'
            onFinish={handleLogin}
            layout='vertical'
        >
            <Form.Item<FieldType>
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please input your email!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item<FieldType>
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item>
                <Button type='primary' htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>,
        signup: <Form
            name='signup'
            onFinish={handleSignUp}
            layout='vertical'
        >
            <Form.Item<FieldType>
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input your name!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item<FieldType>
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please input your email!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item<FieldType>
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item>
                <Button type='primary' htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>,
    };


    const onboardingContent = [
        <Card
            bordered={false}
            tabList={tabs}
            activeTabKey={activeTab}
            onTabChange={changeTab}
            style={{ backgroundColor: theme.bg_2, minWidth: 300 }}
        >
            {content[activeTab]}
        </Card>,
        <Card
            bordered={false}
            style={{ backgroundColor: theme.bg_2, minWidth: 300 }}
            extra={<Button type='text' onClick={() => setOnboarding(2)}>skip</Button>}
        >
            Do you have a project to share with the world?
            <Segmented
                options={['Yes 🎉', 'Not Yet']}
                value={hasProject}
                onChange={setHasProject}
            />
            <br />
            <br />
            {hasProject === 'Yes 🎉'
                ? <EditProject callback={() => { setOnboarding(2) }} />
                : hasProject && <>
                    Just here for the inspo? That's cool too!
                    <Button type='primary' onClick={() => setOnboarding(2)} icon={<RocketOutlined />}>further up and further in</Button>
                </>
            }
        </Card>,
        <Card
            bordered={false}
            style={{ backgroundColor: theme.bg_2, minWidth: 300 }}
            extra={<Button type='text' onClick={() => navigate('/explore', { replace: true })}>skip</Button>}
        >
            <Composer showDivider={false} callback={() => { navigate('/explore', { replace: true }) }} />
        </Card>,
    ]

    function handleLogin(formData: AnyObject) {
        auth.signInWithEmail(formData, () => {
            navigate('/feed', { replace: true });
        });
    }

    function handleSignUp(formData: AnyObject) {
        auth.signUpNewUser(formData, () => {
            setOnboarding(1)
        });
    }

    return (
        <Flex
            align='center'
            justify='center'
            style={{
                minHeight: '100vh',
                backgroundColor: theme.bg,
                paddingInline: 48
            }}
        >
            <Row align={'middle'} justify={'center'} gutter={[48, 48]}>
                <Col style={{ textAlign: 'right' }} xs={24} md={12}>
                    {(activeTab === 'login' && onboarding === 0)
                        ? <>
                            <Typography.Title level={2}>Welcome Back</Typography.Title>
                            <Typography.Text>Inspiration Awaits...</Typography.Text>
                        </>
                        : <>
                            <Typography.Title level={2}>{onboardingText[onboarding].title}</Typography.Title>
                            <Typography.Text>{onboardingText[onboarding].content}</Typography.Text>
                        </>
                    }
                </Col>
                <Col xs={24} md={12}>
                    {onboardingContent[onboarding]}
                </Col>
            </Row>
        </Flex>
    );
};

export default Login;