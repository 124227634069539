import { useEffect, useState } from 'react'
import Card from '../components/Card'
import { Button, Col, Divider, Flex, Row, Space, Typography } from 'antd';
import { UserOutlined, UserAddOutlined, HomeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuth, useSupabase } from '../AuthProvider';
import { useTheme } from '../utilities/ThemeProvider';

function Home() {
  const [projects, setProjects] = useState<any>([])
  const navigate = useNavigate();
  const supabase = useSupabase();
  let { theme } = useTheme();
  let { profile } = useAuth();

  async function fetchProjects() {
    const { data } = await supabase
      .from('projects')
      .select(`
          *,
          profiles(name),
          posts(*),
          liked_projects(count)
      `)
      .limit(5)
      .order('created_at', { ascending: false })

    if (data?.length) setProjects(data)
  }

  useEffect(() => {
    fetchProjects()
  }, []);

  return (
    <div className='App' style={{ backgroundColor: theme.bg }}>
      <header>
        <span className='logo'>logo_coming_soon</span>
        <div>
          {profile
            ? <Button
              type='text'
              onClick={() => navigate('feed')}
              style={{ fontFamily: 'Inconsolata, monospace' }}
              icon={<HomeOutlined style={{ color: theme.tx }} />}
            />
            : <>
              <Button
                type='text'
                onClick={() => navigate('login')}
                style={{ fontFamily: 'Inconsolata, monospace' }}
                icon={<UserOutlined style={{ color: theme.tx }} />}
              >
                Login
              </Button>
              <Divider type='vertical' />
              <Button
                type='text'
                onClick={() => { navigate('/login?type=signup') }}
                style={{ color: theme.tx }}
                icon={<UserAddOutlined style={{ color: theme.tx }} />}
              >
                Sign Up
              </Button>
            </>
          }
        </div>
      </header>
      <div className='hero' style={{ minHeight: '60vh' }}>
        <div className='hero-content'>
          <h1 className='title' style={{ lineHeight: '1em', margin: 0 }}>A place for creatives to show off unfinished projects.</h1>
          <p>* Whether you have a logo yet or not.</p>
          <Space>
            <Button
              type='text'
              onClick={() => navigate('/login?type=signup')}
              style={{
                backgroundColor: theme.gr,
                color: 'white',
                fontWeight: 900,
              }}
            >
              Sign Up
            </Button>
            <Button
              type='text'
              onClick={() => navigate('/login?type=signup')}
              style={{
                fontWeight: 900,
              }}
            >
              Log In
            </Button>
          </Space>
        </div>
      </div>
      {/* FEED: */}
      <Row style={{ backgroundColor: theme.bg_2 }} justify={'center'}>
        <Col span={18}>
          <h1 style={{ textAlign: 'center' }}>Featured Projects:</h1>
          <Flex gap={16} wrap justify='center'>
            {projects.map((project: any, index: number) => (
              <Card key={index} project={project} cardType='abbreviated' />
            ))}
          </Flex>
          <div style={{ height: 40 }}></div>
        </Col>
      </Row>

      <div style={{ height: 40 }}></div>
      <Row justify={'center'}>
        <Col span={18}>
          <Row justify={'center'} align={'middle'} gutter={48}>
            <Col sm={24} md={12}>
              <Typography.Title>Share Your Projects</Typography.Title>
              <Typography.Text>Whether it's large or small, exists on a napkin... or just in your mind, we look forward to seeing what you're cookin!</Typography.Text>
            </Col>
            <Col sm={24} md={12}>
              <img
                style={{
                  width: '100%',
                  height: 'auto',
                  aspectRatio: '16 / 9',
                  objectFit: 'cover',
                }}
                alt='feature highlight'
                src="landing_imgs/newproject.png"
              />
            </Col>
          </Row>
          <Divider />
          <Row justify={'center'} align={'middle'} gutter={48}>
            <Col sm={24} md={12}>
              <img
                style={{
                  width: '100%',
                  height: 'auto',
                  aspectRatio: '16 / 9',
                  objectFit: 'cover',
                }}
                alt='feature highlight'
                src="landing_imgs/post.png"
              />
            </Col>
            <Col sm={24} md={12}>
              <Typography.Title>Provide Updates</Typography.Title>
              <Typography.Text>Share your groundbreaking discoverings and creative breakthroughs with your followers!</Typography.Text>
            </Col>
          </Row>
          <Divider />
          <Row justify={'center'} align={'middle'} gutter={48}>
            <Col sm={24} md={12}>
              <Typography.Title>Connect With Other Creatives</Typography.Title>
              <Typography.Text>Follow your favorite creatives and their projects.</Typography.Text>
            </Col>
            <Col sm={24} md={12}>
              <img
                style={{
                  width: '100%',
                  height: 'auto',
                  aspectRatio: '16 / 9',
                  objectFit: 'cover',
                }}
                alt='feature highlight'
                src="landing_imgs/following.png"
              />
            </Col>
          </Row>
          <Divider />
          <Row justify={'center'} align={'middle'} gutter={48}>
            <Col sm={24} md={12}>
              <img
                style={{
                  width: '100%',
                  height: 'auto',
                  aspectRatio: '16 / 9',
                  objectFit: 'cover',
                }}
                alt='feature highlight'
                src="landing_imgs/projects.png"
              />
            </Col>
            <Col sm={24} md={12}>
              <Typography.Title>Get Inspired</Typography.Title>
              <Typography.Text>There's no better inspiration to see what other creatives are dreaming up!</Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>
      <div style={{ height: 40 }}></div>
      <Row style={{ backgroundColor: theme.bg_2 }} justify={'center'}>
        <Col span={18} style={{ textAlign: 'center' }}>
          <h1>Sign up Today</h1>
          <Space>
            <Button
              type='text'
              onClick={() => navigate('/login?type=signup')}
              style={{
                backgroundColor: theme.gr,
                color: 'white',
                fontWeight: 900,
              }}
            >
              Sign Up
            </Button>
            <Button
              type='text'
              onClick={() => navigate('/login?type=signup')}
              style={{
                fontFamily: 'Inconsolata, monospace',
                fontWeight: 900,
              }}
            >
              Log In
            </Button>
          </Space>
          <div style={{ height: 40 }}></div>
        </Col>
      </Row>

      <footer>
        <p style={{ textAlign: 'center', fontFamily: 'Inconsolata' }}>creating with the garage door open since {new Date().getFullYear()}</p>
      </footer>
    </div>
  )
}

export default Home;
