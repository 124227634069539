import { App, Dropdown, Flex, MenuProps, Space, Typography } from "antd";
import { AnyObject } from "antd/es/_util/type";
import { useTheme } from "../../../utilities/ThemeProvider";
import { useNavigate } from "react-router-dom";
import { CommentOutlined, EllipsisOutlined } from "@ant-design/icons";
import { useAuth, useSupabase } from "../../../AuthProvider";
import Avatar from "../../Avatar";

function Header({
    post,
}: {
    post: AnyObject
}) {
    let { theme } = useTheme();
    let navigate = useNavigate();
    let date = new Date(post.created_at);
    const { profile } = useAuth();
    const supabase = useSupabase();
    const { message } = App.useApp();

    async function deletePost() {
        console.log(post.id)
        await supabase
            .from('posts')
            .delete()
            .eq('id', post.id)
            .eq('profile_id', profile.profile_id)
            .then(() => {
                message.success('Post Deleted!')
            })
    }

    const items: MenuProps['items'] = [
        {
            key: 'delete',
            danger: true,
            label: 'Delete Post',
            onClick: deletePost
        },
    ];

    return (
        <Flex gap={8} justify="space-between" align="center">
            <Space size={'small'}>
                <Avatar name={post.author_name} image={post.author_image} expertise={post.author_expertise}/>
                <Flex vertical gap={0}>
                    <Typography.Link
                        style={{
                            fontSize: '1.2rem',
                            lineHeight: '1rem',
                            margin: 0,
                            padding: 0,
                            color: theme.tx
                        }}
                        onClick={() => { navigate('/profile/' + post.author_id) }}
                    >{post.author_name} {post.parent_post_id && <CommentOutlined style={{ color: theme.tx_3 }} />}</Typography.Link>
                    <Typography.Link
                        style={{
                            lineHeight: '1rem',
                            margin: 0,
                            padding: 0,
                            color: theme.tx_2,
                        }}
                        onClick={() => { navigate('/project/' + post.projects?.id) }}
                    >{post.projects?.name || post.projectname}</Typography.Link>
                </Flex>
            </Space>
            <Space size={'small'}>
                <span style={{ color: theme.tx_3 }}>{date.toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short' })}</span>
                {post.profile_id === profile.profile_id &&
                    <Dropdown menu={{ items }}>
                        <EllipsisOutlined />
                    </Dropdown>
                }
            </Space>
        </Flex>
    )
}

export default Header;
