import { Button, Flex, Space } from "antd";
// import PostType from "../../../types/PostType";
import { AnyObject } from "antd/es/_util/type";
import { useTheme } from "../../../utilities/ThemeProvider";
import { useNavigate } from "react-router-dom";
import { useAuth, useSupabase } from "../../../AuthProvider";
import { useEffect, useState } from "react";
import { HeartTwoTone, HeartOutlined, MessageOutlined } from '@ant-design/icons';

function Footer({
    post,
}: {
    post: AnyObject
}) {
    let { theme } = useTheme();
    let navigate = useNavigate();
    const supabase = useSupabase();
    const { profile } = useAuth();
    const [liked, setLiked] = useState(false)
    const [likeCount, setLikeCount] = useState(0)
    const [commentsCount, setCommentsCount] = useState(0)
    
    useEffect(() => {
        if (post.likes) setLikeCount(post.likes)
        if (post.liked) setLiked(post.liked)
        if (post.comments) setCommentsCount(post.comments)
    }, [post])

    async function likePost() {
        if (!liked && post) {
            await supabase
                .from('liked_posts')
                .insert({
                    profile_id: profile.profile_id,
                    liked_id: post.id,
                })
                .then(() => {
                    setLiked(true);
                    setLikeCount(likeCount + 1)
                })
        } else {
            // unlike
            await supabase
                .from('liked_posts')
                .delete()
                .eq('profile_id', profile.profile_id)
                .eq('liked_id', post?.id)
                .then(() => {
                    setLiked(false)
                    setLikeCount(likeCount - 1)
                })
        }
    }

    return (
        <Flex gap={24} align="center">
            <Space size={'small'} style={{ color: theme.tx }}>
                <Button
                    style={{ color: theme.tx }}
                    icon={liked ? <HeartTwoTone twoToneColor={theme.re} /> : <HeartOutlined />}
                    onClick={likePost}
                    type="text"
                    shape="circle"
                />
                {likeCount}
            </Space>
            {!post.parent_post_id &&
                <Space size={'small'} style={{ color: theme.tx }}>
                    <Button
                        style={{ color: theme.tx }}
                        icon={<MessageOutlined />}
                        onClick={() => { navigate('/post/'+post.id) }}
                        type="text"
                        shape="circle"
                    />
                    {commentsCount}
                </Space>
            }
        </Flex>
    )
}

export default Footer;
