import { Flex, Space, Tag } from 'antd';
import ProjectType from '../types/ProjectType';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTheme } from '../utilities/ThemeProvider';

function Card(
    {
        project,
        cardType = 'full'
    }: {
        project: ProjectType,
        cardType?: 'full' | 'abbreviated'
    }
) {
    const [likesCount, setLikesCount] = useState(0)
    const navigate = useNavigate()
    const { theme } = useTheme()

    useEffect(() => {
        if (project.liked_projects) setLikesCount(project.liked_projects[0].count)
    }, [project])

    function openLink() {
        if (project.profile_id) {
            navigate(`../../project/${project.id}`)
        } else {
            window.open(project.link, '_blank')
        }
    }

    if (!project) return <></>

    if (cardType === 'abbreviated') return (
        <div
            className='feed-card'
            style={{
                border: `2px solid ${theme.ui_2}`,
                borderRadius: 16,
                backgroundColor: theme.bg,
                padding: 4,
                paddingBottom: 8,
                maxWidth: 184,
            }}
            onClick={openLink}
        >
            <img
                style={{
                    width: 'calc(100% - 4px)',
                    maxWidth: 172,
                    height: 120,
                    objectFit: 'cover',
                    borderRadius: '12px 12px 0px 0px',
                    display: 'block',
                    border: `2px solid ${theme.ui_2}`,
                }}
                src={`https://hoxfetyabueltdiqrkck.supabase.co/storage/v1/object/public/thumbnails/${project.thumbnail_url}`}
                alt=''
            />
            <div style={{
                padding: 8
            }}>
                <h2 style={{
                    margin: 0,
                    fontWeight: 900,
                }}>{project.name}</h2>
                <h6 style={{
                    margin: 0,
                }}>{project.profiles ? project.profiles?.name : project.author}</h6>
                <Flex style={{ marginBlockStart: 8 }} wrap gap={8}>
                    {project.profile_id &&
                        <Tag icon='🔥'>{likesCount}</Tag>
                    }
                    <Tag color='#24837B' bordered={false}>{project.status}</Tag>
                </Flex>
            </div>
        </div>
    )

    return (
        <div
            className='feed-card'
            style={{
                border: '2px solid #E6E4D9',
                borderRadius: 16,
                backgroundColor: '#F2F0E5',
                padding: 4,
                paddingBottom: 8,
                maxWidth: 304,
            }}
            onClick={openLink}
        >
            {project.thumbnail_url ? <img
                style={{
                    width: 300,
                    height: 200,
                    objectFit: 'cover',
                    borderRadius: '12px 12px 0px 0px',
                    display: 'block',
                    border: '2px solid #E6E4D9',
                }}
                src={`https://hoxfetyabueltdiqrkck.supabase.co/storage/v1/object/public/thumbnails/${project.thumbnail_url}`}
                alt=''
            /> : <div style={{
                width: 300,
                height: 200,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'Inconsolata',
                fontWeight: 900,
                borderRadius: '12px 12px 0px 0px',
                border: '2px solid #E6E4D9',
                backgroundColor: '#F2F0E5',
            }}>
                logo_coming_soon
            </div>}
            <div style={{
                padding: 8
            }}>
                <h2 style={{
                    color: '#100F0F',
                    margin: 0,
                    fontFamily: 'Inconsolata',
                    fontWeight: 900,
                }}>{project.name}</h2>
                <h6 style={{
                    color: '#6F6E69',
                    margin: 0,
                    fontFamily: 'Inconsolata',
                }}>{project.profiles ? project.profiles?.name : project.author}</h6>
                {project.profile_id &&
                    <Space size={'small'} style={{ marginBlockStart: 8 }}>
                        <Tag icon='🔥'>{likesCount}</Tag>
                        <Tag >{project.posts.length} Updates</Tag>
                    </Space>
                }
                <hr style={{ borderColor: '#E6E4D9' }} />
                <p style={{ fontFamily: 'Inconsolata', }}>{project.description}</p>
                <Tag color='#24837B' bordered={false}>{project.status}</Tag>
            </div>
        </div>
    )
}

export default Card;
