import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Project from './pages/Project';
import BaseLayout from './pages/_base';
import User from './pages/User';
import Login from './pages/Login';
import Timeline from './pages/Timeline';
import { RequireAuth } from './AuthProvider';
import Explore from './pages/Explore';
import SinglePost from './pages/SinglePost';
import About from './pages/About';

function App() {
  return (
    <Routes>
      <Route path="*" element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="login" element={<Login />} />
      <Route element={<BaseLayout />}>
        <Route path="feed" element={<Timeline />} />
        <Route path="explore" element={<Explore />} />
        <Route path="notifications" element={<Timeline v={2} />} />
        <Route path="project/:projectId" element={<Project />} />
        <Route path="profile/:userId" element={<RequireAuth><User /></RequireAuth>} />
        <Route path="post/:postId" element={<RequireAuth><SinglePost /></RequireAuth>} />
      </Route>
    </Routes>
  )
}

export default App;
