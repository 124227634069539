import { useEffect, useState } from 'react'
import Card from '../components/Card'
import { Button, Col, Divider, Flex, Row, Space, Typography } from 'antd';
import { UserOutlined, UserAddOutlined, HomeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuth, useSupabase } from '../AuthProvider';
import { useTheme } from '../utilities/ThemeProvider';
import SocialIcon from '../components/SocialIcon';

function About() {
  const [projects, setProjects] = useState<any>([])
  const navigate = useNavigate();
  const supabase = useSupabase();
  let { theme } = useTheme();
  let { profile } = useAuth();

  async function fetchProjects() {
    const { data } = await supabase
      .from('projects')
      .select(`
          *,
          profiles(name),
          posts(*),
          liked_projects(count)
      `)
      .limit(5)
      .order('created_at', { ascending: false })

    if (data?.length) setProjects(data)
  }

  useEffect(() => {
    fetchProjects()
  }, []);

  return (
    <div className='App' style={{ backgroundColor: theme.bg, minHeight: '100vh' }}>
      <header>
        <span className='logo'>logo_coming_soon</span>
        <div>
          {profile
            ? <Button
              type='text'
              onClick={() => navigate('/feed')}
              style={{ fontFamily: 'Inconsolata, monospace' }}
              icon={<HomeOutlined style={{ color: theme.tx }} />}
            />
            : <>
              <Button
                type='text'
                onClick={() => navigate('/login')}
                style={{ fontFamily: 'Inconsolata, monospace' }}
                icon={<UserOutlined style={{ color: theme.tx }} />}
              >
                Login
              </Button>
              <Divider type='vertical' />
              <Button
                type='text'
                onClick={() => { navigate('/login?type=signup') }}
                style={{ color: theme.tx }}
                icon={<UserAddOutlined style={{ color: theme.tx }} />}
              >
                Sign Up
              </Button>
            </>
          }
        </div>
      </header>

      <div style={{
        textAlign: 'center',
        maxWidth: '800px',
        padding: 20,
        margin: 'auto',
      }}>
        <h1 style={{marginBlockEnd: 0}}>What is logo coming soon?</h1>
        <h5 style={{marginBlockStart: 0}}>And do you <em>actually</em> have a logo coming soon?</h5>
        <h2>💻 🖌️ 🖋️ 📐 🖥️ 🛠️ 🎶 📸 🎥</h2>
        <p>
          <strong>logo_coming_soon</strong> exists to provide creatives with a space to share their creative processes.
          To "work with the garage door up" as <a href='https://notes.andymatuschak.org/Work_with_the_garage_door_up'>Andy Matuschak describes it</a>.
          <br />
          <br />
          "Working with the garage door up" means not hiding your progress until it's perfect.
          It envokes a sense of community, an aspect of openess and transparency.
          Having the "garage door up" allows any passerby to peer in and observe you in your element.
          <br />
          <br />
          Envision with me a neighborhood street on a Saturday morning.
          In one driveway perhaps someone is painting a fence,
          in another corner perhaps a person is weeding their garden and infront of yet another house folks are planting flowers,
          maybe there's an elderly lady sitting on her porch drawing or painting while she sips tea.
          None of these activities are ovrrly glamorous or flashy. Yet at every moment, everywhere you look, there's life, there's activity, and something is being created.
          At any moment, any one of those people can look around them and see they're not alone.
          <br />
          <br />
          I want to create a community where creatives can share their raw and unfiltered work freely, without fear of judgement, where ideas flow naturally and collaboration is abundant.
          Not the finished product that gets posted on their portfolio, but the work that doesn't work.
          The designs that are "missing something" the code that crashes, the music that needs "one more thing",
          the product that's still clunky, and the story that doesn't have an ending.

          We're all creatives here let us celebrate <em>your</em> creativity. Regardless what form it takes.
          <br />
          <br />
          So, that's logo_coming_soon. It's far from perfect, but that's the beauty.
        </p>
        <Divider />
        <p style={{color: theme.tx}}>
          <strong>logo_coming_soon</strong> is built by me, Adam, a solo entrepeneur, builder, developer, and avid coffee drinker.
          <br />
          <br />
          <Flex justify='center' gap={16}>
            <a href='https://x.com/i_spy_coffee' target='_blank' rel='noreferrer'>
              <SocialIcon type='x' />
            </a>
            <a href='https://posts.cv/adam.b' target='_blank' rel='noreferrer'>
              <SocialIcon type='posts' />
            </a>
          </Flex>
        </p>
      </div>

      <footer>
        <p style={{ textAlign: 'center', fontFamily: 'Inconsolata' }}>creating with the garage door open since {new Date().getFullYear()}</p>
      </footer>
    </div>
  )
}

export default About;
