import { Button } from "antd";
import { UserAddOutlined, CheckCircleOutlined, HeartOutlined } from '@ant-design/icons';
import { useTheme } from "../utilities/ThemeProvider";
import { useEffect, useState } from "react";
import { useAuth, useSupabase } from "../AuthProvider";

function FollowButton({
    type,
    id,
    alreadyLiked
}: {
    type: "profile" | "project"
    id: number
    alreadyLiked?: number
}) {
    const { theme } = useTheme()
    const { profile } = useAuth();
    const supabase = useSupabase();
    const [loading, setLoading] = useState(true)
    const [liked, setLiked] = useState((!!alreadyLiked))

    const fetchLike = async () => {
        await supabase
            .from('liked_' + type + 's')
            .select(`*`)
            .eq('profile_id', profile.profile_id)
            .eq('liked_id', id)
            .maybeSingle()
            .then(({ data }) => {
                if (data) setLiked(true)
                setLoading(false)
            })
    }

    const toggleLike = async () => {
        if (!liked) {
            await supabase
                .from('liked_' + type + 's')
                .insert({
                    profile_id: profile.profile_id,
                    liked_id: id,
                })
                .then(() => {
                    setLiked(true);
                })
        }
    }

    useEffect(() => {
        if (liked) {
            setLoading(false)
        } else {
            fetchLike()
        }
    }, [])

    return (
        liked
            ? <></>
            : <Button
                type="text"
                shape="circle"
                loading={loading}
                onClick={toggleLike}
                // style={{
                //     backgroundColor: (liked) ? theme.gr : theme.ui
                // }}
                icon={(liked)
                    ? <CheckCircleOutlined style={{ color: theme.gr }} />
                    : (type === "project"
                        ? <HeartOutlined />
                        : <UserAddOutlined style={{ color: theme.tx }} />
                    )
                }
            />

    )
}

export default FollowButton;
