import {
    Button,
    Card,
    Col,
    Divider,
    Flex,
    Grid,
    List,
    Modal,
    Row,
    Skeleton,
    Space,
    Tag,
    Typography
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectType from '../../types/ProjectType';
import {
    LinkOutlined,
    EditOutlined,
    LoginOutlined,
    UserAddOutlined,
    HeartOutlined,
    HeartTwoTone
} from '@ant-design/icons';
import '../../App.css';
import { useTheme } from '../../utilities/ThemeProvider';
import { useAuth, useSupabase } from '../../AuthProvider';
import { Composer, Post } from '../../components/Feed';
import EditProject from './editProject';

const { useBreakpoint } = Grid;

function Project() {
    const [projectModalOpen, setProjectModalOpen] = useState(false);
    const [project, setProject] = useState<ProjectType>()
    const [myProject, setMyProject] = useState(false)
    const [liked, setLiked] = useState(false)
    const [likeCount, setLikeCount] = useState(0)

    const screens = useBreakpoint();
    let params = useParams();
    let { theme } = useTheme();
    const supabase = useSupabase();
    const navigate = useNavigate();
    const { session, profile } = useAuth();

    async function fetchProject(projectId: string) {
        const { data } = await supabase
            .from('projects')
            .select(`
                *,
                profiles(profile_id, name),
                posts(
                    *,
                    profiles(
                        profile_id,
                        profile_image,
                        name
                    ),
                    likes:liked_posts(profile_id),
                    comments:posts(count)
                ),
                liked_projects(count)
            `)
            .eq('id', projectId)
            .order('created_at', { referencedTable: 'posts', ascending: false })
            .single()

        if (data) {
            setLikeCount(data.liked_projects[0].count)
            setProject(data)
            if (session) {
                setMyProject(data.profile_id === profile.profile_id)

                await supabase
                    .from('liked_projects')
                    .select()
                    .eq('profile_id', profile.profile_id)
                    .eq('liked_id', data.id)
                    .then(({ data }) => {
                        if (data && data.length > 0) setLiked(true)
                    })
            }
        }
    }

    useEffect(() => {
        if (params?.projectId) {
            fetchProject(params?.projectId)
        }
    }, [params]);

    async function likeProject() {
        if (!liked && project) {
            await supabase
                .from('liked_projects')
                .insert({
                    profile_id: profile.profile_id,
                    liked_id: project.id,
                })
                .then(() => {
                    setLiked(true);
                    setLikeCount(likeCount + 1)
                })
        } else {
            // unlike
            await supabase
                .from('liked_projects')
                .delete()
                .eq('profile_id', profile.profile_id)
                .eq('liked_id', project?.id)
                .then(() => {
                    setLiked(false)
                })
        }
    }

    const toggleProjectModal = () => {
        setProjectModalOpen(!projectModalOpen);
    };

    if (!project) return (
        <Flex
            style={{
                width: '100vw',
                height: '100vh',
                textAlign: 'center',
            }}
            align='center'
            justify='center'
        >
            <p>
                loading...
            </p>
        </Flex>
    )

    return (
        <Row justify="center" align="top" gutter={16}>
            {/* SIDEBAR */}
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Flex vertical align='flex-end'>
                    {project.thumbnail_url ? <img
                        style={{
                            width: '100%',
                            height: 200,
                            objectFit: 'cover',
                            borderRadius: `${(screens.md) ? '16px 0px 0px 0px' : '16px 16px 0px 0px'}`,
                            display: 'block',
                            border: `2px solid ${theme.ui_3}`,
                        }}
                        src={`https://hoxfetyabueltdiqrkck.supabase.co/storage/v1/object/public/thumbnails/${project.thumbnail_url}`}
                        alt=''
                    /> : <div style={{
                        width: '100%',
                        height: 200,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontFamily: 'Inconsolata',
                        fontWeight: 900,
                        borderRadius: `${(screens.md) ? '16px 0px 0px 0px' : '16px 16px 0px 0px'}`,
                        border: `2px solid ${theme.ui_3}`,
                        backgroundColor: theme.bg_2,
                        color: theme.tx
                    }}>
                        logo_coming_soon
                    </div>}
                    <Space>
                        <Typography.Title
                            level={1}
                            style={{
                                color: theme.tx,
                                marginBlock: 0,
                                fontFamily: 'Inconsolata',
                            }}
                        >{project.name}</Typography.Title>
                    </Space>
                    <Typography.Link
                        style={{
                            color: theme.tx_2,
                            margin: 0,
                            fontFamily: 'Inconsolata',
                        }}
                        onClick={() => {
                            navigate('../../profile/' + project.profile_id)
                        }}
                    >
                        <Tag color={theme.cy} bordered={false}>{project.status}</Tag>
                        by: {project.profiles ? project.profiles?.name : project.author}
                    </Typography.Link>
                    <Flex gap={8}>
                        {myProject
                            ? <Button
                                style={{
                                    color: theme.tx,
                                    backgroundColor: theme.bg,
                                    borderColor: theme.ui,
                                }}
                                onClick={toggleProjectModal}
                                size='small'
                            >
                                <EditOutlined /> edit
                            </Button>
                            : <Button
                                style={{
                                    color: theme.tx,
                                }}
                                icon={liked ? <HeartTwoTone twoToneColor={theme.re} /> : <HeartOutlined />}
                                onClick={likeProject}
                                size={liked ? 'small' : 'middle'}
                                shape='round'
                                type="default"
                            >
                                {liked ? 'Liked' : 'Like'}
                            </Button>
                        }
                    </Flex>
                    <Divider style={{ marginBlock: 8, borderColor: theme.ui }} />
                    <Typography.Text
                        style={{
                            fontFamily: 'Inconsolata',
                            color: theme.tx,
                            textAlign: 'right',
                        }}
                    >
                        {project.description}
                    </Typography.Text>
                    {project.link && <Button
                        style={{
                            color: theme.tx,
                            backgroundColor: theme.bg,
                            borderColor: theme.ui,
                        }}
                        onClick={() => window.open(project.link, '_blank')}
                        size='small'
                        icon={<LinkOutlined />}
                    >
                        Visit Project
                    </Button>}
                    <Divider style={{ marginBlock: 8, borderColor: theme.ui }} />
                </Flex>
            </Col>
            {/* FEED */}
            <Col xs={24} sm={20} md={16} lg={16} xl={16}>
                {myProject &&
                    <Composer
                        projectId={project.id}
                        callback={() => fetchProject(String(project.id))}
                    />
                }
                {session
                    ? <List
                        itemLayout="vertical"
                        dataSource={project.posts}
                        renderItem={(post) => (
                            <List.Item>
                                <Post post={{
                                    ...post,
                                    author_name: post.profiles.name,
                                    author_image: post.profiles.profile_image,
                                    likes: post.likes?.length,
                                    comments: post.comments ? post.comments[0].count : 0,
                                    liked: post.likes?.some(e => e.profile_id === profile?.profile_id),
                                }} />
                            </List.Item>
                        )}
                    />
                    : <Flex justify='center' align='center' vertical>
                        <Card style={{ width: '100%', marginBlockEnd: 16, color: theme.tx }}>
                            <Button size='small' onClick={() => { navigate('/login') }} style={{ color: theme.tx }}>
                                <UserAddOutlined /> Sign Up
                            </Button>
                            <span> or </span>
                            <Button size='small' onClick={() => { navigate('/login') }} style={{ color: theme.tx }}>
                                <LoginOutlined /> Login
                            </Button>
                            <span> to view all {project.posts.length} updates for {project.name}.</span>
                        </Card>
                        <Skeleton avatar paragraph={{ rows: 2 }} />
                        <Divider />
                        <Skeleton avatar paragraph={{ rows: 2 }} />
                    </Flex>
                }

            </Col>
            <Modal
                title="Update Project"
                open={projectModalOpen} onCancel={toggleProjectModal}
                footer={[]}
            >
                <EditProject projectModel={project} />
            </Modal>
        </Row>
    )
}

export default Project;
