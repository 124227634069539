import { List } from "antd";
import { Composer, Timeline as FeedTimeline } from "../components/Feed";
import { useNotifications } from "../utilities/Database";
import { HeartOutlined, UserAddOutlined } from '@ant-design/icons';

function Timeline({
    v = 1
}: {
    v?: number
}) {
    const notifications = useNotifications();

    if (v === 1) {
        return (
            <>
                <Composer />
                <FeedTimeline src='feed_timeline' />
            </>
        )
    }

    return (
        <List
            itemLayout="vertical"
            dataSource={notifications}
            renderItem={(item) => (
                <List.Item>
                    <List.Item.Meta
                        style={{margin: 0}}
                        avatar={item.action === 'followed you' ? <UserAddOutlined /> : <HeartOutlined />}
                        title={<a href={`/profile/${item.user_id}`}><strong>{item.user_name}</strong> {item.action}</a>}
                        description={item.content}
                    />
                </List.Item>
            )}
        />
    )

}

export default Timeline;
