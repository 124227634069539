import { Col, Image, Row, Space, } from "antd";
import { useTheme } from "../../../utilities/ThemeProvider";
import { AnyObject } from "antd/es/_util/type";
import Header from "./_header";
import Footer from "./_footer";
import { useNavigate } from "react-router-dom";

function Post({
    post,
    enableLinks = true
}: {
    post: AnyObject
    enableLinks?: boolean
}) {
    let { theme } = useTheme();
    let navigate = useNavigate();

    function openPost() {
        if (enableLinks) navigate('/post/' + (post.parent_post_id ?? post.id))
    }

    return (
        <div>
            <Header post={post} />
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={post.image_url ? 12 : 24} lg={post.image_url ? 12 : 24} xl={post.image_url ? 12 : 24}>
                    <Space style={{ marginBlock: 16, color: theme.tx, whiteSpace: 'pre-line', width: '100%' }} onClick={openPost}>
                        {post.update}
                    </Space>
                </Col>
                {post.image_url &&
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Image
                            alt={post.update}
                            src={`https://hoxfetyabueltdiqrkck.supabase.co/storage/v1/object/public/posts/${post.image_url}`}
                            style={{
                                width: '100%',
                                borderRadius: '8px',
                                border: `2px solid ${theme.ui}`
                            }}
                            preview={{
                                toolbarRender: () => (<></>)
                            }}
                        />
                    </Col>
                }
            </Row>
            <Footer post={post} />
        </div>
    )
}

export default Post;
