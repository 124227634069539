import { useEffect, useState } from "react";
import { Button, Input, Form, Flex, App, Space, Progress, Upload, GetProp, UploadProps } from "antd";
import { useTheme } from "../../utilities/ThemeProvider";
import { useAuth, useSupabase } from "../../AuthProvider";
import { PictureOutlined } from '@ant-design/icons';
import { AnyObject } from "antd/es/_util/type";

const prompts = [
    'What are you creating today?',
    'What\'s blocking your creative process?',
    'What\'s the most exciting thing you\'re working on?',
    'Where are you getting inspiration from?',
    'What\'s the most challenging thing you\'re working on?',
    'Coffee or Tea?',
]

function Composer({
    projectId = null,
    showDivider = true,
    callback,
    parentId = null,
    customPrompt,
}: {
    projectId?: number | null
    showDivider?: boolean
    callback?: () => void
    parentId?: number | null
    customPrompt?: string | null
}) {
    const [loading, setLoading] = useState(false);
    let { theme } = useTheme();
    const supabase = useSupabase();
    const { profile } = useAuth();
    const [form] = Form.useForm();
    const { message } = App.useApp();
    const postContent = Form.useWatch('update', form);
    const postImage = Form.useWatch('image', form);
    const [prompt, setPrompt] = useState('');

    useEffect(() => {
        if (customPrompt) {
            setPrompt(customPrompt);
        } else {
            setPrompt(prompts[Math.floor(Math.random() * prompts.length)]);
        }
    }, []);

    async function post(formData: AnyObject) {
        setLoading(true);
        let imgUrl = null;

        if (formData.image?.fileList.length > 0) {
            await uploadImage(formData.image.fileList[0].originFileObj).then((res) => {
                imgUrl = res;
            });

            if (!imgUrl) {
                setLoading(false);
                return;
            }
        }

        await supabase.from('posts').insert({
            update: formData.update,
            image_url: imgUrl,
            project_id: projectId,
            profile_id: profile.profile_id,
            parent_post_id: parentId,
        }).then(({data, error}) => {
            if (!error) {
                setLoading(false);
                form.resetFields();
                message.success('Posted');
                if (callback) callback();
                return;
            }

            setLoading(false);
            message.error('Something went wrong: ' + error?.message);
            if (callback) callback();
        });
        setLoading(false);
    }

    type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

    async function uploadImage(file: FileType) {
        // Upload image
        return await supabase.storage
            .from('posts')
            .upload(
                profile.id + '/' + file.uid,
                file,
                {
                    cacheControl: '3600',
                    upsert: false
                }
            ).then(({ data, error }) => {
                if (error) {
                    message.error('Error: ' + error.message);
                    return false;
                } else if (data) {
                    return data.path;
                }
            }).catch((error) => {
                message.error(error.message);
                return false;
            })
    }

    const beforeUpload = (file: FileType) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }

        return false;
    };

    return (
        <div style={showDivider ? { borderBottom: `1px solid ${theme.ui}` } : {}}>
            <Form
                form={form}
                name='post'
                onFinish={post}
                layout='vertical'
            >
                <Form.Item
                    name="update"
                    style={{ padding: 0, margin: 0 }}
                    rules={[
                        {required: true, message: "You've gotta at least say *something*" },
                        { max: 1000, message: "Feeling wordy today huh? Longer text is coming soon, don't worry." }
                    ]}
                >
                    <Input.TextArea
                        autoSize
                        placeholder={prompt}
                    />
                </Form.Item>
                <Flex justify="space-between" style={{ padding: 0, marginBlock: 4 }}>
                    <Space size={'small'}>
                        {!parentId &&
                            <Form.Item
                                name={'image'}
                                style={{padding: 0, margin: 0}}
                            >
                                <Upload
                                    accept="image/png, image/jpeg"
                                    maxCount={1}
                                    beforeUpload={beforeUpload}
                                    fileList={postImage && postImage.fileList}
                                >
                                    {(!postImage || !(postImage.fileList.length > 0)) &&
                                        <Button
                                            type='text'
                                            size="small"
                                        >
                                            <PictureOutlined />
                                        </Button>
                                    }
                                </Upload>
                            </Form.Item>
                        }
                        {/* <Select
                            placeholder="Project"
                            variant="borderless"
                            style={{ flex: 1 }}
                            options={[
                                { value: 'jack', label: 'Jack' },
                                { value: 'lucy', label: 'Lucy' },
                                { value: 'Yiminghe', label: 'yiminghe' },
                            ]}
                        /> */}
                    </Space>
                    <Space align="center">
                        {postContent &&
                            <Progress
                                type="circle"
                                percent={(postContent.length / 1000) * 100}
                                size={16}
                                strokeColor={theme.tx}
                            />
                        }
                        <Form.Item style={{ padding: 0, margin: 0 }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="small"
                                loading={loading}
                            >
                                Post
                            </Button>
                        </Form.Item>
                    </Space>
                </Flex>
            </Form>
        </div>
    )
}

export default Composer;
