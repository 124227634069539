import { useEffect, useState } from "react";
import { useAuth, useSupabase } from "../AuthProvider";
import PostType from "../types/PostType";
import { AnyObject } from "antd/es/_util/type";

export const usePosts = () => {
    const [posts, setPosts] = useState<PostType[]>();
    const { profile } = useAuth();

    const supabase = useSupabase();

    useEffect(() => {
        async function fetchPosts() {
            await supabase
                .from('feed_timeline')
                .select('*')
                .then((response) => {
                    if (response.data) setPosts(response.data);
                })
        }

        fetchPosts();
    }, [supabase])

    return posts;
}

export const useNotifications = () => {
    const [notifs, setNotifs] = useState<AnyObject[]>();
    const supabase = useSupabase();

    useEffect(() => {
        async function fetchPosts() {
            await supabase
                .from('notifications')
                .select('*')
                .then((response) => {
                    if (response.data) setNotifs(response.data);
                })
        }

        fetchPosts();
    }, [supabase])

    return notifs;
}