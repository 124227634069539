import React, { useEffect } from "react";
import { AnyObject } from "antd/es/_util/type";
import { useState } from 'react'
import { createClient } from '@supabase/supabase-js'
import Login from "./pages/Login";
import { App } from "antd";

interface AuthContextType {
  session: AnyObject;
  user: AnyObject;
  profile: AnyObject;
  signInWithEmail: (user: AnyObject, callback: VoidFunction) => void;
  signUpNewUser: (user: AnyObject, callback: VoidFunction) => void;
  signOut: (callback: VoidFunction) => void;
  updateProfile: (userData: AnyObject) => Promise<void>;
  updateEmail: (newEmail: string) => Promise<void>;
}

let AuthContext = React.createContext<AuthContextType>(null!);

const supabase = createClient(
  'https://hoxfetyabueltdiqrkck.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImhveGZldHlhYnVlbHRkaXFya2NrIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTU5MTA3NDgsImV4cCI6MjAzMTQ4Njc0OH0.-3qoKxfOXImq9k9YCveS0gKDoISUw19e1hJpsJg0dNg'
)

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [session, setSession] = useState<any>(null);
  let [user, setUser] = useState<any>(null);
  let [profile, setProfile] = useState<any>(null);
  let [loading, setLoading] = useState(false);
  const { message } = App.useApp();

  useEffect(() => {
    setLoading(true);
    const getUser = async () => {
      const { data: { user: currentUser } } = await supabase.auth.getUser();
      const { data: { session: currentSession } } = await supabase.auth.getSession();
      if (currentUser) {
        const { data: userProfile } = await supabase
          .from('profiles')
          .select()
          .eq('id', currentUser?.id)
          .single();

        setProfile(userProfile);
      }

      setSession(currentSession ?? null);
      setUser(currentUser ?? null);
      setLoading(false);
    };
    getUser();

    supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'USER_UPDATED') {
        // TODO: I'm not sure if we want to set this...
        // setSession(session);
        setUser(session?.user);
      }
    })
  }, []);

  async function signUpNewUser(user: AnyObject, callback: VoidFunction) {
    const { data, error } = await supabase.auth.signUp({
      email: user.email,
      password: user.password,
      options: {
        data: {
          name: user.name
        }
      }
    })

    if (error) {
      console.error(error)
    } else if (data) {
      if (data.user) {
        const { data: userProfile } = await supabase
          .from('profiles')
          .select()
          .eq('id', data.user?.id)
          .single();

        setProfile(userProfile);
      }

      setSession(data.session);
      setUser(data.user);
    }

    callback();
  }

  async function signInWithEmail(user: AnyObject, callback: VoidFunction) {
    const { data, error } = await supabase.auth.signInWithPassword({
      email: user.email,
      password: user.password,
    })

    if (error) {
      message.error(error.message);
    } else if (data) {
      // Set the user id when a user logs in
      const { data: userProfile } = await supabase
        .from('profiles')
        .select()
        .eq('id', data.user?.id)
        .single();

      setProfile(userProfile);

      setSession(data.session);
      setUser(data.user);
      callback();
    }
  }

  async function signOut(callback: VoidFunction) {
    await supabase.auth.signOut().then(() => {
      setUser(null);
      setProfile(null);
      setSession(null);
      callback();
    })
  }

  async function updateEmail(newEmail: string) {
    return await supabase.auth.updateUser({
      email: newEmail
    })
    .then(({data, error}) => {
      if (error) {
        message.error("Failed to update email "+error.message);
      } else if (data) {
        message.success('Email Updated!');
      }
    })
  }

  async function updateProfile(userData: AnyObject) {
    return await supabase
      .from('profiles')
      .update(userData)
      .eq('id', user.id)
      .select()
      .single()
      .then(({ data }) => {
        message.success('Updated!');
        setProfile(data);
      });
  }

  let value = {
    session,
    user,
    profile,
    supabase,
    signInWithEmail,
    signOut,
    signUpNewUser,
    updateProfile,
    updateEmail,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function useSupabase() {
  return supabase;
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  let { user } = useAuth();

  return (
    <>
      {user ? children : <Login />}
    </>
  )
}