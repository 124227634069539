import { Flex, List, Space, Typography } from "antd";
import { useAuth, useSupabase } from "../AuthProvider";
import { useEffect, useState } from "react";
import { useTheme } from "../utilities/ThemeProvider";
import { AnyObject } from "antd/es/_util/type";
import { useNavigate } from "react-router-dom";
import FollowButton from "../components/Follow";
import Avatar from "../components/Avatar";

function Explore() {
    const [profiles, setProfiles] = useState<AnyObject[]>([])
    const [projects, setProjects] = useState<AnyObject[]>([])
    const supabase = useSupabase();
    const { profile } = useAuth();
    const { theme } = useTheme();
    const navigate = useNavigate()

    async function fetchProfiles() {
        await supabase
            .from('profiles')
            .select(`
                name,
                profile_id,
                profile_image,
                bio,
                liked_profiles!liked_profiles_liked_id_fkey(count)
            `)
            // exclude test account
            .neq('profile_id', 2)
            // exclude your own profile
            .neq('profile_id', profile.profile_id)
            .limit(20)
            .then(({ data }) => {
                if (data) setProfiles(data)
            })
    }

    async function fetchProjects() {
        await supabase
            .from('projects')
            .select(`
                *,
                profiles(name),
                posts(*),
                liked_projects(count)
            `)
            .not('profile_id', 'is', null)
            .limit(4)
            .then(({ data }) => {
                if (data) setProjects(data)
            })
    }

    useEffect(() => {
        if (profile) fetchProfiles();
        fetchProjects();
    }, [profile]);

    return (
        <>
            <List
                itemLayout="vertical"
                dataSource={projects}
                renderItem={(project) => (
                    <List.Item>
                        <Flex justify="space-between">
                            <Space align="center" onClick={() => navigate("/project/" + project.id)}>
                                <Avatar name={project.name} image={project.thumbnail_url} type="project"/>
                                <div>
                                    <Typography.Title level={4} style={{ margin: 0 }}>{project.name}</Typography.Title>
                                    <Typography.Text style={{ margin: 0 }}>{project.description}</Typography.Text>
                                </div>
                            </Space>
                            <FollowButton type="project" id={project.id} />
                        </Flex>
                    </List.Item>
                )}
            />
            <List
                itemLayout="vertical"
                dataSource={profiles}
                renderItem={(account) => (
                    <List.Item>
                        <Flex justify="space-between">
                            <Space align="center" onClick={() => navigate("/profile/" + account.profile_id)}>
                                <Avatar name={account.name} image={account.profile_image}/>
                                <div>
                                    <Typography.Title level={4} style={{ margin: 0 }}>{account.name}</Typography.Title>
                                    <Typography.Text style={{ margin: 0 }}>{account.bio}</Typography.Text>
                                </div>
                            </Space>
                            <FollowButton type="profile" id={account.profile_id} alreadyLiked={account.liked_profiles[0].count}/>
                        </Flex>
                    </List.Item>
                )}
            />
        </>
    )

}

export default Explore;
